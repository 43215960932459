import membershipGaragesData from '$server/data/all_membership_garage_data.json';
import { IS_LOCAL_RUNTIME } from '@/util';

export type OneTrustDomainsList = { hostname: string; oneTrustScriptId: string }[];

const oneTrustLocalDomainsList = [
  {
    hostname: 'hellofixter.co.uk',
    oneTrustScriptId: '0191dccb-bcee-7a03-86eb-e53375a6cbd0',
  },
  {
    hostname: 'hellofixter.fr',
    oneTrustScriptId: '0191e662-e1db-7ef3-b7e9-de0588055bf9',
  },
  {
    hostname: 'fixter-garage-test.co.uk',
    oneTrustScriptId: '0191e650-ea55-7d31-b62c-ace0d9835450',
  },
  {
    hostname: 'fixter-nemo.local',
    oneTrustScriptId: '' /** @todo Replace with the correct oneTrustScriptId when it's set up */,
  },
];

const oneTrustFixterDomainsList = [
  {
    hostname: 'fixter.co.uk',
    oneTrustScriptId: 'eb46ec6d-6f83-4fda-b33e-1f4d2c89fc5a',
  },
  {
    hostname: 'fixter.fr',
    oneTrustScriptId: 'c53bd861-1abd-4a9d-853a-43b8ca1263a3',
  },
  {
    hostname: 'fixter-dev.co.uk',
    oneTrustScriptId: 'a9a3440b-179e-42e5-97b5-46ab0e259d9b',
  },
  {
    hostname: 'fixter-dev.fr',
    oneTrustScriptId: '53e4111b-5b87-4429-98ca-fd75e061335b',
  },
  {
    hostname: 'fixter-staging.co.uk',
    oneTrustScriptId: '45c31846-fee8-489d-a853-b9e2a213f4c5',
  },
  {
    hostname: 'fixter-staging.fr',
    oneTrustScriptId: '9eb3d128-7170-4bb1-b719-26f4b51a548f',
  },
  {
    hostname: 'partakus.com',
    oneTrustScriptId: '' /** @todo Replace with the correct oneTrustScriptId when it's set up */,
  },
];

const oneTrustDomainsList: OneTrustDomainsList = IS_LOCAL_RUNTIME
  ? oneTrustLocalDomainsList
  : [...oneTrustFixterDomainsList, ...membershipGaragesData];

export const getOneTrustScriptId = (hostname: string) => {
  const oneTrustConfig = oneTrustDomainsList.find((item) => hostname.includes(item.hostname));
  return oneTrustConfig?.oneTrustScriptId;
};
