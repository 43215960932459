import config from 'config';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import qs from 'qs';
import { Locale, Segment } from '@fxtr/i18n';
/**
 * @todo don't import server data on client
 */
import Partners from '$data/Partners';
import { getClientTld } from '@/util/domain';
import { cookieOptions } from './cookieOptions';
import { isLocale } from './domains';

import { isFunctionalCategoryActive, isTargetingCategoryActive } from './oneTrust';

const NOT_HOME_PAGE = Object.freeze([
  '/contact-us',
  '/about-us',
  '/our-services',
  '/cars',
  '/how-it-works',
  '/partners',
  '/terms-conditions',
  '/privacy-policy',
  '/error',
]);

const NOT_HOME_PAGE_STARTS_WITH = Object.freeze(['/checkout', '/customer', '/login']);

const {
  fixterPartner,
  acquisitionMethod,
  fixterHome,
  fixterLanding,
  EnableMotServiceBundle,
  fixterEmailCampaign,
  fixterMarketingCampaign,
  fixterSegment,
  trackBooking,
  fixterMembershipGarage,
  awin,
} = cookieOptions;

const isSomeHomePage = () =>
  !NOT_HOME_PAGE_STARTS_WITH.some((startPathname) => window.location.pathname.startsWith(startPathname)) &&
  !NOT_HOME_PAGE.includes(window.location.pathname);

const shouldRemoveGarageMembershipCookie = () =>
  !['/checkout', '/customer', '/login'].some((startPathname) =>
    window.location.pathname.startsWith(startPathname)
  ) && !['/error'].includes(window.location.pathname);

/**
 * Remove membership garage related cookies
 */
export const maybeResetMembershipGarageCookie = () => {
  if (shouldRemoveGarageMembershipCookie()) {
    Cookies.remove('fixterMembershipGarage', {
      domain: getClientTld(),
      secure: fixterMembershipGarage.secure,
      httpOnly: fixterMembershipGarage.httpOnly,
      path: '/',
    });
    Cookies.remove('fixterMembershipGarageDomain', {
      domain: getClientTld(),
      secure: fixterMembershipGarage.secure,
      httpOnly: fixterMembershipGarage.httpOnly,
      path: '/',
    });

    return true;
  }

  return false;
};

export const setAcquisitionMethodPartner = (partner) => {
  Cookies.set('acquisitionMethod', 'partner', acquisitionMethod);
  Cookies.set('fixterPartner', partner, fixterPartner);
};

/**
 * @warn keep in sync with the same constant in `readPromoCampaign`
 */
const ALLOWED_UTM_MEDIUMS = [
  'print',
  'partnership',
  'call',
  'cpc',
  'social',
  'company_profile',
  'vod',
  'awin',
  'paid_social',
  'partner',
  'display',
  'email',
  'affiliate',
  'referral',
  'organic',
  'premium_membership',
  'sales',
  'cs',
  'fleets',
];
export const isExpectedMarketingUtmMedium = (utmMedium = '') => ALLOWED_UTM_MEDIUMS.includes(utmMedium);

export const deleteFunctionalCookies = () => {
  Cookies.remove('fixterHome', {
    domain: fixterHome.domain,
    secure: fixterHome.secure,
    httpOnly: fixterHome.httpOnly,
    path: '/',
  });
  Cookies.remove('fixterLanding', {
    domain: fixterLanding.domain,
    secure: fixterLanding.secure,
    httpOnly: fixterLanding.httpOnly,
    path: '/',
  });
  Cookies.remove('acquisitionMethod', {
    domain: acquisitionMethod.domain,
    secure: acquisitionMethod.secure,
    httpOnly: acquisitionMethod.httpOnly,
    path: '/',
  });
  Cookies.remove('fixterPartner', {
    domain: fixterPartner.domain,
    secure: fixterPartner.secure,
    httpOnly: fixterPartner.httpOnly,
    path: '/',
  });
  Cookies.remove('EnableMotServiceBundle', {
    domain: EnableMotServiceBundle.domain,
    secure: EnableMotServiceBundle.secure,
    httpOnly: EnableMotServiceBundle.httpOnly,
    path: '/',
  });
  Cookies.remove('fixterEmailCampaign', {
    domain: fixterEmailCampaign.domain,
    secure: fixterEmailCampaign.secure,
    httpOnly: fixterEmailCampaign.httpOnly,
    path: '/',
  });
  Cookies.remove('fixterMarketingCampaign', {
    domain: fixterMarketingCampaign.domain,
    secure: fixterMarketingCampaign.secure,
    httpOnly: fixterMarketingCampaign.httpOnly,
    path: '/',
  });
  Cookies.remove('trackBooking', {
    domain: trackBooking.domain,
    secure: trackBooking.secure,
    httpOnly: trackBooking.httpOnly,
    path: '/',
  });
};

/**
 * @deprecated use App Router `PageSetupEffects` component instead
 */
export default function setHomeCookies() {
  const partners = Partners.map(({ slug }) => `/${slug}`);

  const setFixterHome = () => {
    const pathname = window.location.pathname.replace(/\/$/, ''); // pathname without trailing slash
    const homePath = window.location.origin + pathname; // url without query params
    if (isSomeHomePage() && (!Cookies.get('fixterHome') || !Cookies.get('fixterLanding'))) {
      Cookies.set('fixterHome', homePath, fixterHome);
      Cookies.set('fixterLanding', pathname, fixterLanding);
    }
  };

  const setMotServiceBundle = () => {
    const isMotAndServicePage = window.location.href.includes('mot-and-service');
    const pathname = (window.location.pathname || '').replace(/\/$/, '');

    if (partners.includes(pathname)) return;

    if (isMotAndServicePage) {
      Cookies.set('EnableMotServiceBundle', true, EnableMotServiceBundle);
    }
  };

  const prepareAcquisitionMethodPartnerFromPathname = () => {
    const pathname = get(window.location, 'pathname', '').replace(/\/$/, ''); // pathname without trailing slash
    const partnerCookie = Cookies.getJSON('fixterPartner');

    if (!partnerCookie) {
      Cookies.set('acquisitionMethod', 'noPartner', acquisitionMethod);
      Cookies.set('fixterPartner', null, fixterPartner);
    }

    if (partners.includes(pathname)) {
      const partner = pathname.indexOf('/') === 0 ? pathname.substr(1).toLowerCase() : pathname;
      setAcquisitionMethodPartner(partner);
    }
  };

  /**
   * Add cookies when redirecting from the relevant marketing campaigns or partners links
   * eg: https://www.fixter.fr/?campaignId=dbb7d60a-ed1f-434a-be65-6bcb2e2f787d&utm_source=kontiki&utm_medium=partner&utm_campaign=email30
   */
  const prepareAcquisitionMethodPartnerFromQuery = () => {
    const qp = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const utmSource = get(qp, 'utm_source', '');
    const utmMedium = get(qp, 'utm_medium', '');

    if (utmSource && utmMedium === 'partner') {
      setAcquisitionMethodPartner(utmSource);
    }
  };

  /**
   * We don't want to display the discount banner and we also don't want to automatically
   * add the discount to the basket when the marketing team sends out email campaigns.
   * They will be using the query parameter `campaign` on the email URLs, which we track internally
   * VIA the fixterEmailCampaign cookie for persistency reasons.
   * eg: /mot?campaign=repeatEmailMarketing
   */
  const setFixterEmailCampaign = () => {
    const qp = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const campaignQP = get(qp, 'campaign');
    if (campaignQP && campaignQP === 'repeatEmailMarketing')
      Cookies.set('fixterEmailCampaign', true, fixterEmailCampaign);
  };

  const setFixterMarketingCampaign = () => {
    const qp = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const campaignId = get(qp, 'campaignId');
    if (!campaignId) return;

    const utmMedium = get(qp, 'utm_medium');

    const isValidCampaign = isExpectedMarketingUtmMedium(utmMedium);

    if (!isValidCampaign) return;

    Cookies.set('fixterMarketingCampaign', campaignId, fixterMarketingCampaign);
  };

  const setSegmentCookie = () => {
    if (isLocale([Locale.FR_FR])) {
      Cookies.set('fixterSegment', Segment.Fixter_FR, fixterSegment);
    } else {
      Cookies.set('fixterSegment', Segment.Fixter_UK, fixterSegment);
    }
  };

  const setActiveCampaignDataFromURLParams = () => {
    const urlParams = new URL(window.location.href).searchParams;

    if (urlParams.get('utm_source') === 'ActiveCampaign' && urlParams.get('utm_medium') === 'email') {
      const email = decodeURIComponent(urlParams.get('email'));
      const contactEmail = email.replace(/^[^a-zA-Z0-9]+/, '').replace(/[^a-zA-Z0-9]+$/, ''); // remove leading and trailing non-alphanumeric characters
      const campaignId = decodeURIComponent(urlParams.get('campaignId'));
      const campaign = decodeURIComponent(urlParams.get('utm_campaign'));

      const activeCampaingParams = {
        campaignId,
        campaign,
        contactEmail,
      };

      Cookies.set('activeCampaignData', JSON.stringify(activeCampaingParams));
    }
  };

  const setAwinCookie = () => {
    const urlParams = new URL(window.location.href).searchParams;
    const awc = urlParams.get('awc');

    if (awc) {
      Cookies.set('awc', awc, awin);
    }
  };

  // functional - according to our own cookie policy page https://www.fixter.co.uk/cookie-policy
  if (isFunctionalCategoryActive()) {
    prepareAcquisitionMethodPartnerFromPathname();
    prepareAcquisitionMethodPartnerFromQuery();
    setFixterHome();
    setMotServiceBundle();
    setFixterEmailCampaign();
    setFixterMarketingCampaign();
  }

  if (config.get('public.features.activeCampaignEventTracking.enabled') && isTargetingCategoryActive()) {
    setActiveCampaignDataFromURLParams();
  }
  // should be essential actually
  setSegmentCookie();

  if (config.get('public.awin.enabled')) {
    setAwinCookie();
  }
}
